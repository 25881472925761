export const environment = {
  production: false,
  staging: true,
  //apiUrl: 'https://hermes-devbackend.pulzo2.com',
  apiUrlRobby: 'https://hermes-dev-files.pulzo2.com/documents',
  apiUrl: 'https://hermes-back-qa.pulzo2.com',
  pageSize: 25,
  sentry: 'https://f280ea7005af4e5580159281a9ac1353@o1114920.ingest.sentry.io/6249611',
  release: '5.5.6',
};
